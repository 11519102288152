
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace('')
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as abuseReportsjhDqhcnA1oMeta } from "/home/admin/www/pronomen.net/release/2025-01-28--20-08-24/pages/admin/abuseReports.vue?macro=true";
import { default as _91id_938mVm80ShpgMeta } from "/home/admin/www/pronomen.net/release/2025-01-28--20-08-24/pages/admin/audit-log/[username]/[id].vue?macro=true";
import { default as editorZTZjE9YHCxMeta } from "/home/admin/www/pronomen.net/release/2025-01-28--20-08-24/pages/admin/blog/editor.vue?macro=true";
import { default as indexhT3FWO2l6LMeta } from "/home/admin/www/pronomen.net/release/2025-01-28--20-08-24/pages/admin/index.vue?macro=true";
import { default as moderationmmYhFYcFnPMeta } from "/home/admin/www/pronomen.net/release/2025-01-28--20-08-24/pages/admin/moderation.vue?macro=true";
import { default as pendingBansGFVB9rtyEdMeta } from "/home/admin/www/pronomen.net/release/2025-01-28--20-08-24/pages/admin/pendingBans.vue?macro=true";
import { default as profilesVZcwHsg6dsMeta } from "/home/admin/www/pronomen.net/release/2025-01-28--20-08-24/pages/admin/profiles.vue?macro=true";
import { default as expenseshgMLI976WJMeta } from "/home/admin/www/pronomen.net/release/2025-01-28--20-08-24/pages/admin/timesheets/expenses.vue?macro=true";
import { default as index8AZ13NA3p0Meta } from "/home/admin/www/pronomen.net/release/2025-01-28--20-08-24/pages/admin/timesheets/index.vue?macro=true";
import { default as overviewGdOHUdhEmVMeta } from "/home/admin/www/pronomen.net/release/2025-01-28--20-08-24/pages/admin/timesheets/overview.vue?macro=true";
import { default as awaitingv30fkcqhUbMeta } from "/home/admin/www/pronomen.net/release/2025-01-28--20-08-24/pages/admin/translations/awaiting.vue?macro=true";
import { default as missingirUDq3MYgGMeta } from "/home/admin/www/pronomen.net/release/2025-01-28--20-08-24/pages/admin/translations/missing.vue?macro=true";
import { default as usersx8JGT2vprnMeta } from "/home/admin/www/pronomen.net/release/2025-01-28--20-08-24/pages/admin/users.vue?macro=true";
import { default as apiiNpqL7gCEuMeta } from "/home/admin/www/pronomen.net/release/2025-01-28--20-08-24/pages/api.vue?macro=true";
import { default as _91slug_93DtU9IjmQ3EMeta } from "/home/admin/www/pronomen.net/release/2025-01-28--20-08-24/pages/blog/[slug].vue?macro=true";
import { default as indexdnRFDrnWd9Meta } from "/home/admin/www/pronomen.net/release/2025-01-28--20-08-24/pages/blog/index.vue?macro=true";
import { default as _91_91year_93_93PryrxvsXqVMeta } from "/home/admin/www/pronomen.net/release/2025-01-28--20-08-24/pages/calendar/[[year]].vue?macro=true";
import { default as _91year_93_45_91month_93_45_91day_931wulY77XBeMeta } from "/home/admin/www/pronomen.net/release/2025-01-28--20-08-24/pages/calendar/[year]-[month]-[day].vue?macro=true";
import { default as adminNgfsNz53C7Meta } from "/home/admin/www/pronomen.net/release/2025-01-28--20-08-24/pages/census/admin.vue?macro=true";
import { default as indexMKTzCrlTTSMeta } from "/home/admin/www/pronomen.net/release/2025-01-28--20-08-24/pages/census/index.vue?macro=true";
import { default as contactqY6gTczhwnMeta } from "/home/admin/www/pronomen.net/release/2025-01-28--20-08-24/pages/contact.vue?macro=true";
import { default as design1pRWvwp5dFMeta } from "/home/admin/www/pronomen.net/release/2025-01-28--20-08-24/pages/design.vue?macro=true";
import { default as englishOh5FXn8VQyMeta } from "/home/admin/www/pronomen.net/release/2025-01-28--20-08-24/pages/english.vue?macro=true";
import { default as faqTXryKyGTp6Meta } from "/home/admin/www/pronomen.net/release/2025-01-28--20-08-24/pages/faq.vue?macro=true";
import { default as inclusivec245KD3dCXMeta } from "/home/admin/www/pronomen.net/release/2025-01-28--20-08-24/pages/inclusive.vue?macro=true";
import { default as index_45homeqDLvzXhkF4Meta } from "/home/admin/www/pronomen.net/release/2025-01-28--20-08-24/pages/index-home.vue?macro=true";
import { default as index_45localetJKgN8arZlMeta } from "/home/admin/www/pronomen.net/release/2025-01-28--20-08-24/pages/index-locale.vue?macro=true";
import { default as langswitch9Kk7DEhHhNMeta } from "/home/admin/www/pronomen.net/release/2025-01-28--20-08-24/pages/langswitch.vue?macro=true";
import { default as licenseXH3p7hlN4EMeta } from "/home/admin/www/pronomen.net/release/2025-01-28--20-08-24/pages/license.vue?macro=true";
import { default as academicqAJYphS4XXMeta } from "/home/admin/www/pronomen.net/release/2025-01-28--20-08-24/pages/links/academic.vue?macro=true";
import { default as index6Zw3GNNagOMeta } from "/home/admin/www/pronomen.net/release/2025-01-28--20-08-24/pages/links/index.vue?macro=true";
import { default as mediaFcceQVexODMeta } from "/home/admin/www/pronomen.net/release/2025-01-28--20-08-24/pages/links/media.vue?macro=true";
import { default as translinguisticsDRKpPQcVVZMeta } from "/home/admin/www/pronomen.net/release/2025-01-28--20-08-24/pages/links/translinguistics.vue?macro=true";
import { default as zineFL78dkfAMdMeta } from "/home/admin/www/pronomen.net/release/2025-01-28--20-08-24/pages/links/zine.vue?macro=true";
import { default as names2JNgqN3Qc3Meta } from "/home/admin/www/pronomen.net/release/2025-01-28--20-08-24/pages/names.vue?macro=true";
import { default as indexZ1nVMGwtWMMeta } from "/home/admin/www/pronomen.net/release/2025-01-28--20-08-24/pages/nouns/index.vue?macro=true";
import { default as templatesPlKH9toh5BMeta } from "/home/admin/www/pronomen.net/release/2025-01-28--20-08-24/pages/nouns/templates.vue?macro=true";
import { default as peopleoqn6IsIu1GMeta } from "/home/admin/www/pronomen.net/release/2025-01-28--20-08-24/pages/people.vue?macro=true";
import { default as privacynjLurHWaRGMeta } from "/home/admin/www/pronomen.net/release/2025-01-28--20-08-24/pages/privacy.vue?macro=true";
import { default as _91username_93v8y0pYmkrcMeta } from "/home/admin/www/pronomen.net/release/2025-01-28--20-08-24/pages/profile/[username].vue?macro=true";
import { default as card_45_91username_93Rr9115wjmYMeta } from "/home/admin/www/pronomen.net/release/2025-01-28--20-08-24/pages/profile/card-[username].vue?macro=true";
import { default as editorxt3DARLuh1Meta } from "/home/admin/www/pronomen.net/release/2025-01-28--20-08-24/pages/profile/editor.vue?macro=true";
import { default as any0PD3cbJL89Meta } from "/home/admin/www/pronomen.net/release/2025-01-28--20-08-24/pages/pronouns/any.vue?macro=true";
import { default as askXLG6wD9s22Meta } from "/home/admin/www/pronomen.net/release/2025-01-28--20-08-24/pages/pronouns/ask.vue?macro=true";
import { default as avoidingL9DNtZ3mJpMeta } from "/home/admin/www/pronomen.net/release/2025-01-28--20-08-24/pages/pronouns/avoiding.vue?macro=true";
import { default as indexYp41X8d3aCMeta } from "/home/admin/www/pronomen.net/release/2025-01-28--20-08-24/pages/pronouns/index.vue?macro=true";
import { default as mirrorEckKXeMhy9Meta } from "/home/admin/www/pronomen.net/release/2025-01-28--20-08-24/pages/pronouns/mirror.vue?macro=true";
import { default as pronounROqAuStbpjMeta } from "/home/admin/www/pronomen.net/release/2025-01-28--20-08-24/pages/pronouns/pronoun.vue?macro=true";
import { default as sourcesFGtvgELa1pMeta } from "/home/admin/www/pronomen.net/release/2025-01-28--20-08-24/pages/sources.vue?macro=true";
import { default as teamUlnfx1tJflMeta } from "/home/admin/www/pronomen.net/release/2025-01-28--20-08-24/pages/team.vue?macro=true";
import { default as terminologyNYLkal8hNFMeta } from "/home/admin/www/pronomen.net/release/2025-01-28--20-08-24/pages/terminology.vue?macro=true";
import { default as termsFLN5QWdHJtMeta } from "/home/admin/www/pronomen.net/release/2025-01-28--20-08-24/pages/terms.vue?macro=true";
import { default as userzlQ3WFcQVaMeta } from "/home/admin/www/pronomen.net/release/2025-01-28--20-08-24/pages/user.vue?macro=true";
import { default as workshopspKLEmqdAhLMeta } from "/home/admin/www/pronomen.net/release/2025-01-28--20-08-24/pages/workshops.vue?macro=true";
import { default as deklinationsmusterHoOdm86sCDMeta } from "~/data/nouns/deklinationsmuster.vue?macro=true";
export default [
  {
    name: "admin-abuseReports",
    path: "/admin/abuse-reports",
    meta: abuseReportsjhDqhcnA1oMeta || {},
    component: () => import("/home/admin/www/pronomen.net/release/2025-01-28--20-08-24/pages/admin/abuseReports.vue")
  },
  {
    name: "admin-audit-log-username-id",
    path: "/admin/audit-log/:username()/:id()",
    component: () => import("/home/admin/www/pronomen.net/release/2025-01-28--20-08-24/pages/admin/audit-log/[username]/[id].vue")
  },
  {
    name: "admin-blog-editor",
    path: "/admin/blog/editor",
    component: () => import("/home/admin/www/pronomen.net/release/2025-01-28--20-08-24/pages/admin/blog/editor.vue")
  },
  {
    name: "admin",
    path: "/admin",
    component: () => import("/home/admin/www/pronomen.net/release/2025-01-28--20-08-24/pages/admin/index.vue")
  },
  {
    name: "admin-moderation",
    path: "/admin/moderation",
    component: () => import("/home/admin/www/pronomen.net/release/2025-01-28--20-08-24/pages/admin/moderation.vue")
  },
  {
    name: "admin-pendingBans",
    path: "/admin/pending-bans",
    meta: pendingBansGFVB9rtyEdMeta || {},
    component: () => import("/home/admin/www/pronomen.net/release/2025-01-28--20-08-24/pages/admin/pendingBans.vue")
  },
  {
    name: "admin-profiles",
    path: "/admin/profiles",
    component: () => import("/home/admin/www/pronomen.net/release/2025-01-28--20-08-24/pages/admin/profiles.vue")
  },
  {
    name: "admin-timesheets-expenses",
    path: "/admin/timesheets/expenses",
    component: () => import("/home/admin/www/pronomen.net/release/2025-01-28--20-08-24/pages/admin/timesheets/expenses.vue")
  },
  {
    name: "admin-timesheets",
    path: "/admin/timesheets",
    component: () => import("/home/admin/www/pronomen.net/release/2025-01-28--20-08-24/pages/admin/timesheets/index.vue")
  },
  {
    name: "admin-timesheets-overview",
    path: "/admin/timesheets/overview",
    component: () => import("/home/admin/www/pronomen.net/release/2025-01-28--20-08-24/pages/admin/timesheets/overview.vue")
  },
  {
    name: "admin-translations-awaiting",
    path: "/admin/translations/awaiting",
    component: () => import("/home/admin/www/pronomen.net/release/2025-01-28--20-08-24/pages/admin/translations/awaiting.vue")
  },
  {
    name: "admin-translations-missing",
    path: "/admin/translations/missing",
    component: () => import("/home/admin/www/pronomen.net/release/2025-01-28--20-08-24/pages/admin/translations/missing.vue")
  },
  {
    name: "admin-users",
    path: "/admin/users",
    component: () => import("/home/admin/www/pronomen.net/release/2025-01-28--20-08-24/pages/admin/users.vue")
  },
  {
    name: "api",
    path: "/api",
    meta: apiiNpqL7gCEuMeta || {},
    component: () => import("/home/admin/www/pronomen.net/release/2025-01-28--20-08-24/pages/api.vue")
  },
  {
    name: "blog-slug",
    path: "/blog/:slug()",
    meta: _91slug_93DtU9IjmQ3EMeta || {},
    component: () => import("/home/admin/www/pronomen.net/release/2025-01-28--20-08-24/pages/blog/[slug].vue")
  },
  {
    name: "blog",
    path: "/blog",
    meta: indexdnRFDrnWd9Meta || {},
    component: () => import("/home/admin/www/pronomen.net/release/2025-01-28--20-08-24/pages/blog/index.vue")
  },
  {
    name: "calendar",
    path: "/calendar/:year?",
    meta: _91_91year_93_93PryrxvsXqVMeta || {},
    component: () => import("/home/admin/www/pronomen.net/release/2025-01-28--20-08-24/pages/calendar/[[year]].vue")
  },
  {
    name: "calendarDay",
    path: "/calendar/:year()-:month()-:day()",
    meta: _91year_93_45_91month_93_45_91day_931wulY77XBeMeta || {},
    component: () => import("/home/admin/www/pronomen.net/release/2025-01-28--20-08-24/pages/calendar/[year]-[month]-[day].vue")
  },
  {
    name: "census-admin",
    path: "/census/admin",
    meta: adminNgfsNz53C7Meta || {},
    component: () => import("/home/admin/www/pronomen.net/release/2025-01-28--20-08-24/pages/census/admin.vue")
  },
  {
    name: "census",
    path: "/census",
    meta: indexMKTzCrlTTSMeta || {},
    component: () => import("/home/admin/www/pronomen.net/release/2025-01-28--20-08-24/pages/census/index.vue")
  },
  {
    name: "contact",
    path: "/contact",
    meta: contactqY6gTczhwnMeta || {},
    component: () => import("/home/admin/www/pronomen.net/release/2025-01-28--20-08-24/pages/contact.vue")
  },
  {
    name: "design",
    path: "/design",
    component: () => import("/home/admin/www/pronomen.net/release/2025-01-28--20-08-24/pages/design.vue")
  },
  {
    name: "english",
    path: "/english",
    meta: englishOh5FXn8VQyMeta || {},
    component: () => import("/home/admin/www/pronomen.net/release/2025-01-28--20-08-24/pages/english.vue")
  },
  {
    name: "faq",
    path: "/faq",
    meta: faqTXryKyGTp6Meta || {},
    component: () => import("/home/admin/www/pronomen.net/release/2025-01-28--20-08-24/pages/faq.vue")
  },
  {
    name: "inclusive",
    path: "/inclusive",
    meta: inclusivec245KD3dCXMeta || {},
    component: () => import("/home/admin/www/pronomen.net/release/2025-01-28--20-08-24/pages/inclusive.vue")
  },
  {
    name: "index-home",
    path: "/index-home",
    meta: index_45homeqDLvzXhkF4Meta || {},
    component: () => import("/home/admin/www/pronomen.net/release/2025-01-28--20-08-24/pages/index-home.vue")
  },
  {
    name: "index",
    path: "/index-locale",
    meta: index_45localetJKgN8arZlMeta || {},
    component: () => import("/home/admin/www/pronomen.net/release/2025-01-28--20-08-24/pages/index-locale.vue")
  },
  {
    name: "langswitch",
    path: "/langswitch",
    meta: langswitch9Kk7DEhHhNMeta || {},
    component: () => import("/home/admin/www/pronomen.net/release/2025-01-28--20-08-24/pages/langswitch.vue")
  },
  {
    name: "license",
    path: "/license",
    component: () => import("/home/admin/www/pronomen.net/release/2025-01-28--20-08-24/pages/license.vue")
  },
  {
    name: "links-academic",
    path: "/links/academic",
    meta: academicqAJYphS4XXMeta || {},
    component: () => import("/home/admin/www/pronomen.net/release/2025-01-28--20-08-24/pages/links/academic.vue")
  },
  {
    name: "links",
    path: "/links",
    meta: index6Zw3GNNagOMeta || {},
    component: () => import("/home/admin/www/pronomen.net/release/2025-01-28--20-08-24/pages/links/index.vue")
  },
  {
    name: "links-media",
    path: "/links/media",
    meta: mediaFcceQVexODMeta || {},
    component: () => import("/home/admin/www/pronomen.net/release/2025-01-28--20-08-24/pages/links/media.vue")
  },
  {
    name: "links-translinguistics",
    path: "/links/translinguistics",
    meta: translinguisticsDRKpPQcVVZMeta || {},
    component: () => import("/home/admin/www/pronomen.net/release/2025-01-28--20-08-24/pages/links/translinguistics.vue")
  },
  {
    name: "links-zine",
    path: "/links/zine",
    meta: zineFL78dkfAMdMeta || {},
    component: () => import("/home/admin/www/pronomen.net/release/2025-01-28--20-08-24/pages/links/zine.vue")
  },
  {
    name: "names",
    path: "/names",
    meta: names2JNgqN3Qc3Meta || {},
    component: () => import("/home/admin/www/pronomen.net/release/2025-01-28--20-08-24/pages/names.vue")
  },
  {
    name: "nouns",
    path: "/nouns",
    meta: indexZ1nVMGwtWMMeta || {},
    component: () => import("/home/admin/www/pronomen.net/release/2025-01-28--20-08-24/pages/nouns/index.vue")
  },
  {
    name: "nouns-templates",
    path: "/nouns/templates",
    meta: templatesPlKH9toh5BMeta || {},
    component: () => import("/home/admin/www/pronomen.net/release/2025-01-28--20-08-24/pages/nouns/templates.vue")
  },
  {
    name: "people",
    path: "/people",
    meta: peopleoqn6IsIu1GMeta || {},
    component: () => import("/home/admin/www/pronomen.net/release/2025-01-28--20-08-24/pages/people.vue")
  },
  {
    name: "privacy",
    path: "/privacy",
    meta: privacynjLurHWaRGMeta || {},
    component: () => import("/home/admin/www/pronomen.net/release/2025-01-28--20-08-24/pages/privacy.vue")
  },
  {
    name: "profile-username",
    path: "/profile/:username()",
    meta: _91username_93v8y0pYmkrcMeta || {},
    component: () => import("/home/admin/www/pronomen.net/release/2025-01-28--20-08-24/pages/profile/[username].vue")
  },
  {
    name: "profile-card-username",
    path: "/profile/card-:username()",
    meta: card_45_91username_93Rr9115wjmYMeta || {},
    component: () => import("/home/admin/www/pronomen.net/release/2025-01-28--20-08-24/pages/profile/card-[username].vue")
  },
  {
    name: "profile-editor",
    path: "/profile/editor",
    meta: editorxt3DARLuh1Meta || {},
    component: () => import("/home/admin/www/pronomen.net/release/2025-01-28--20-08-24/pages/profile/editor.vue")
  },
  {
    name: "pronouns-any",
    path: "/pronouns/any",
    meta: any0PD3cbJL89Meta || {},
    component: () => import("/home/admin/www/pronomen.net/release/2025-01-28--20-08-24/pages/pronouns/any.vue")
  },
  {
    name: "pronouns-ask",
    path: "/pronouns/ask",
    meta: askXLG6wD9s22Meta || {},
    component: () => import("/home/admin/www/pronomen.net/release/2025-01-28--20-08-24/pages/pronouns/ask.vue")
  },
  {
    name: "pronouns-avoiding",
    path: "/pronouns/avoiding",
    meta: avoidingL9DNtZ3mJpMeta || {},
    component: () => import("/home/admin/www/pronomen.net/release/2025-01-28--20-08-24/pages/pronouns/avoiding.vue")
  },
  {
    name: "pronouns",
    path: "/pronouns",
    meta: indexYp41X8d3aCMeta || {},
    component: () => import("/home/admin/www/pronomen.net/release/2025-01-28--20-08-24/pages/pronouns/index.vue")
  },
  {
    name: "pronouns-mirror",
    path: "/pronouns/mirror",
    meta: mirrorEckKXeMhy9Meta || {},
    component: () => import("/home/admin/www/pronomen.net/release/2025-01-28--20-08-24/pages/pronouns/mirror.vue")
  },
  {
    name: "all",
    path: "/:path(.*)",
    meta: pronounROqAuStbpjMeta || {},
    component: () => import("/home/admin/www/pronomen.net/release/2025-01-28--20-08-24/pages/pronouns/pronoun.vue")
  },
  {
    name: "sources",
    path: "/sources",
    meta: sourcesFGtvgELa1pMeta || {},
    component: () => import("/home/admin/www/pronomen.net/release/2025-01-28--20-08-24/pages/sources.vue")
  },
  {
    name: "team",
    path: "/team",
    meta: teamUlnfx1tJflMeta || {},
    component: () => import("/home/admin/www/pronomen.net/release/2025-01-28--20-08-24/pages/team.vue")
  },
  {
    name: "terminology",
    path: "/terminology",
    meta: terminologyNYLkal8hNFMeta || {},
    component: () => import("/home/admin/www/pronomen.net/release/2025-01-28--20-08-24/pages/terminology.vue")
  },
  {
    name: "terms",
    path: "/terms",
    meta: termsFLN5QWdHJtMeta || {},
    component: () => import("/home/admin/www/pronomen.net/release/2025-01-28--20-08-24/pages/terms.vue")
  },
  {
    name: "user",
    path: "/user",
    meta: userzlQ3WFcQVaMeta || {},
    component: () => import("/home/admin/www/pronomen.net/release/2025-01-28--20-08-24/pages/user.vue")
  },
  {
    name: "workshops",
    path: "/workshops",
    meta: workshopspKLEmqdAhLMeta || {},
    component: () => import("/home/admin/www/pronomen.net/release/2025-01-28--20-08-24/pages/workshops.vue")
  },
  {
    name: "nouns-deklinationsmuster",
    path: "/deklinationsmuster",
    component: () => import("~/data/nouns/deklinationsmuster.vue")
  }
]